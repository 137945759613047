import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import { filterFunnel } from 'res/images';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, exportToCsv, timeFormat } from '../Helper';
import Loader from '../Loader';
import Pagination from '../Pagination';
import CommonAlert from '../CommonAlert';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FilterModal, FilterChips } from 'whealth-core-web/components';
import { useFilter } from 'whealth-core-web/components/FilterContext';
import { checkObjectLength } from 'whealth-core-web/components/Helper';

function Invoices() {
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [alert, setAlert] = useState({});
  const { invoiceFilterData: filterData, setInvoiceFilterData: setFilterData } = useFilter();
  const [clearFiltersData, setClearFilterData] = useState(false);
  const value = useRef('');

  let defaultObj = {
    page: currentPage,
    date_range: '',
    vendor: '',
    order_id: searchStr,
  };

  useEffect(() => {
    setFilterData({});
    sessionStorage.removeItem('invoiceFilterData');
  }, []);

  useEffect(() => {
    getFilteredOrders();
  }, [currentPage, filterData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterData]);

  useEffect(() => {
    searchOrders();
  }, [searchStr]);

  const searchOrders = () => {
    setIsLoading(true);
    const storedFilterData = JSON.parse(sessionStorage.getItem('invoiceFilterData'));
    let data = storedFilterData ? storedFilterData : filterData;
    let searchFilterDataParams = createFilterParams(data, defaultObj);
    ApiClient.getFilteredInvoices(searchFilterDataParams)
      .then((res) => {
        if (value.current === searchFilterDataParams.search_str) {
          setTotalPages(res.headers['total-pages']);
          setOrderList(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('getOrders', err.response);
      });
  };

  const createFilterParams = (filterData, defaultObj) => {
    let params = { ...defaultObj };
    if (filterData.date) {
      if (Array.isArray(filterData.date)) {
        params.date_range = '';
      } else {
        params.date_range = filterData.date;
      }
    }

    if (filterData.vendor_ids) {
      params.vendor = filterData.vendor_ids;
    }

    return params;
  };

  const getFilteredOrders = () => {
    setIsLoading(true);
    const storedFilterData = JSON.parse(sessionStorage.getItem('invoiceFilterData'));
    let data = storedFilterData ? storedFilterData : filterData;

    let params = createFilterParams(data, defaultObj);
    if (params.date_range) {
      params.date_range = String(params.date_range);
    }
    ApiClient.getFilteredInvoices(params)
      .then((res) => {
        setTotalPages(res.data.total_pages);
        setOrderList(res.data.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('getFilteredOrders err: ', err);
        setIsLoading(false);
      });
  };

  const exportCsv = () => {
    setIsLoading(true);
    ApiClient.exportInvoiceCsv()
      .then((res) => {
        exportToCsv(res.data, `Invoices_${dateFormat(new Date())}-${timeFormat(new Date())}.csv`);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('exportCsv', err.response);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className="w-100">
        <CoreInputBox
          placeholder="Search Order ID"
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="headerFiles">
        <AdminPanelHeader hideSearch iconTitle="receipt" title="Invoice" searchBox={renderSearchBox()} />
      </div>
    );
  };

  const rightSideData = [
    {
      title: 'Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filterFunnel,
    },
  ];
  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={rightSideData} />;
  }, []);

  const getFilterData = (data) => {
    setFilterData(data);
    sessionStorage.setItem('invoiceFilterData', JSON.stringify(data));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilterData(new Object());
    setClearFilterData(!clearFiltersData);
    sessionStorage.removeItem('invoiceFilterData');
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ['tag_ids', 'tags_data', 'category_ids', 'author_ids'];
    if (checkObjectLength(filterData, ignoreKeys)) {
      return (
        <div className="mt-2 pt-1">
          <FilterChips
            clearData={clearFilters}
            setData={setFilterData}
            data={filterData}
            sessionStorageKey="invoiceFilterData"
          />
        </div>
      );
    } else {
      if (Object.keys(filterData).length > 0) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);

  const showFilters = useCallback(() => {
    return (
      <FilterModal
        clearFiltersData={clearFiltersData}
        setClearFilterData={setClearFilterData}
        filter="invoices"
        getData={getFilterData}
        data={filterData}
      />
    );
  }, [clearFiltersData, filterData]);

  const showOrders = () => (
    <div className="mb-4">
      <div className="articleHeader stickyHeader">
        {renderHeader()}
        <div className="hrLine mb-3" />
      </div>
      <div className="px-4 wrapperContent">
        <div className="d-flex justify-content-end mb-4">
          {renderFilterChips}
          <div className="d-flex flex-row">
            {renderDashBoardHeader}
            <div
              onClick={() => {
                exportCsv();
              }}
              className="reusableOutlineBtn reusableBtn ms-2"
            >
              Export
            </div>
            <div
              onClick={() => {
                navigate(`/invoices/add-invoice`);
              }}
              className="reusableBtnActive reusableBtn ms-2"
            >
              Add Invoice
            </div>
          </div>
        </div>
        {alert.type == 'alert-success' && (
          <CommonAlert className="mt-3" setIsShowAlert={setAlert} isShowAlert={alert.message} alertType={alert.type} />
        )}
        <div className="table-responsive order-table">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  <b>Order ID</b>
                </th>
                <th scope="col">
                  <b>Vendor Name</b>
                </th>
                <th scope="col">
                  <b>Invoice Date</b>
                </th>
                <th scope="col">
                  <b>Invoice Amount</b>
                </th>
                <th scope="col">
                  <b>Action</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {orderList?.length || isLoading ? (
                orderList.map((item, index) => {
                  return (
                    <tr key={item?.order} style={{ height: '50px' }}>
                      <td>{item?.order_details?.order_id}</td>
                      <td>
                        <b>{item?.items[0]?.vendor?.vendor_name}</b>
                      </td>
                      <td>{item?.invoice_date}</td>
                      <td>₹ {item?.vendor_amount}</td>

                      <td>
                        <div
                          onClick={() => {
                            navigate(`/orders/${item?.order}/${item?.user}/view-invoice/${item?.items[0]?.vendor?.id}`);
                          }}
                          style={{ color: '#243B86', cursor: 'pointer' }}
                        >
                          View Details
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    {' '}
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-4">
        {(orderList?.length > 9 || currentPage > 1) && (
          <Pagination
            data={orderList}
            length={orderList?.count}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="wrapper">
        <Loader show={isLoading} />
        {showOrders()}
        {showFilters()}
        <ToastContainer toastStyle={{ backgroundColor: '#EBF8E7' }} />
      </div>
    </>
  );
}

export default Invoices;
