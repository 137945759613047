import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'rsuite';
import CommonAlert from '../CommonAlert';
import CoreButton from '../CoreButton';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, profileImage, timeFormat, titlizeString } from '../Helper';
import Loader from '../Loader';
import {
  expandIcon,
  action,
  editIcon,
  editOrder,
  checkCircle,
  cancelCircle,
  checkCircleInactive,
  cancelCircleInactive,
  downloadIcon,
} from '../../assets/images';
import './RenderApolloTab.css';
import { Dropdown } from 'rsuite';
import { AdminPanelHeader } from 'components';
import PatientPrescription from '../Orders/PatientPrescription';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateManualOrderMutation, useAddGenericNotesMutation } from 'redux/dashboardApis';
import { location, noteSave, blueEdit } from 'res/images';
import { setReduxNotesData } from 'redux/Slices';
import usePrevious from 'whealth-core-web/hooks/usePrevious';
import CommonToolTip from '../CommonToolTip';
import InvoiceModal from '../InvoiceModal';
import PayoutModal from '../PayoutModal';
import { ToastContainer } from 'react-toastify';

function RenderApolloTab(props) {
  //const { orderId: urlOrderId, patientId: urlPatientId } = useParams();
  const { orderLoadingState, setOrderLoadingState } = props;

  const screenLoading = props?.screenLoading;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patientManualDetails = useSelector((state) => state.sliceReducer.patientManualDetails);
  const patientDetails = useSelector((state) => state.sliceReducer.patientDetails);
  const fullOrderDetail = useSelector((state) => state.sliceReducer.orderDetail);
  const manualOrderId = useSelector((state) => state?.sliceReducer?.manualOrderId);
  const userData = useSelector((state) => state?.sliceReducer?.userData);
  const reduxNotesData = useSelector((state) => state?.sliceReducer?.reduxNotesData);
  const uselocation = useLocation();
  const [orderDetail, setOrderDetail] = useState(props?.order);
  const [orderStatusArray, setOrderStatusArray] = useState([]);
  const [editOrderStatusArray, setEditOrderStatusArray] = useState([]);
  const [errorMsgToBeFull, setErrorMsgToBeFull] = useState([]);
  const [clickDropdown, setClickDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState('');
  const [alertType, setAlertType] = useState('alert-success');
  const [isEditMode, setEditMode] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [patientApiDetails, setPatientApiDetails] = useState({});
  const [updateClicked, setUpdateClicked] = useState(false);
  const [showSubOrderModal, setShowSubOrderModal] = useState(false);
  const [vendorValueArray, setVendorValueArray] = useState([]);
  const [venderOptions, setVendorOptions] = useState([]);
  const [isEditable, setIsEditable] = useState([]);
  const [selectedOrderDetail, setSelectedOrderDetail] = useState();
  const [orderQuantity, setOrderQuantity] = useState([]);
  const [notes, setNotes] = useState('');
  const [notesArr, setNotesArr] = useState([]);
  const [pendingItemErrorMsg, setPendingItemErrorMsg] = useState('');
  const [updateOrderErrMsg, setUpdateOrderErrMsg] = useState('');
  const [isOrderStatusEditable, setIsOrderStatusEditable] = useState([]);
  const [isFulfillOrderStatus, setIsFulfillOrderStatus] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState([]);
  const [fulfilledItemErrMsg, setFulfilledItemErrMsg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState('');
  const [orderSummary, setOrderSummary] = useState({});
  const [manualOrderDetails, setManualOrderDetails] = useState();
  const value = useRef('');
  const containerRef = useRef(null);
  const ApiClient = useApiManager();
  const { id, index, orderId, patientId } = useParams();
  const [genericNotes, genericNotesResponse] = useAddGenericNotesMutation();
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const prevOrderId = usePrevious(orderDetail[index]?.order_id);
  const [vendorOrder, setVendorOrder] = useState(null);
  const [payoutData, setPayoutData] = useState(null);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (
      prevOrderId !== undefined &&
      prevOrderId !== orderDetail[index]?.order_id &&
      !uselocation.pathname.includes('/patient/')
    ) {
      goBack();
    }
  }, [prevOrderId, orderDetail[index]?.order_id]);

  // useEffect(() => {
  //   if (props && props?.order) {
  //     const id = props?.order[0]?.user?.id;
  //     getPatientDetails(patientId || id);
  //   }
  // }, []);
  // useEffect(() => {
  //   if (patientId) {
  //     getSelectedOrderDetail(patientId, orderId);
  //   }
  // }, []);
  useEffect(() => {
    getVendorDetail();
  }, [id]);
  const [updateManualOrder, updateManualOrderData] = useUpdateManualOrderMutation();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !event.target.closest('.table') &&
        !event.target.closest('.order-dropdown') &&
        !event.target.closest('.order-dropdown-part') &&
        !updateClicked
      ) {
        setEditOrderStatusArray([]);
        setClickDropdown(false);
        setIsDropdownOpen([]);
        // Reset edited values when clicking outside without updating
        setOrderDetail((prevOrderDetail) => {
          const resetOrderDetail = prevOrderDetail?.map((item, i) => ({
            ...item,
            fulfilled_order: item.fulfilled_order?.map((fulfilledItem) => ({
              ...fulfilledItem,
              order_items: fulfilledItem.order_items?.map((medicineItem) => ({
                ...medicineItem,
                quantity: medicineItem.fulfilled_qty,
              })),
            })),
          }));
          return resetOrderDetail;
        });
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [updateClicked]);

  useEffect(() => {
    dispatch(setReduxNotesData(notesArr));
  }, [notesArr]);

  useEffect(() => {
    if (props && props.order) {
      const id = props?.order[0]?.user?.id;
      getPatientDetails(patientId || id);
      setOrderDetail(props.order);
    }
  }, [props?.order]);

  useEffect(() => {
    if (orderId) {
      setIsNotesLoading(true);
      getManualOrderDetail(orderId);
      getAllNotes(orderId);
    } else if (manualOrderId) {
      setIsNotesLoading(true);
      getManualOrderDetail(manualOrderId);
      getAllNotes(manualOrderId);
    }
  }, []);

  const getManualOrderDetail = (id) => {
    //const id = orderId ? orderId : manualOrderId
    ApiClient.getManualOrder(id)
      .then((res) => {
        setManualOrderDetails(res);
        const discount = parseFloat(res?.data?.discounted_amount);
        const disc_per = ((discount / parseFloat(res?.data?.before_discount_amount)) * 100).toFixed(2);
        setOrderSummary({
          order_total: parseFloat(res?.data?.before_discount_amount),
          shipping_amount: parseFloat(res?.data?.shipping_amount),
          discount: discount,
          discount_percentage: disc_per,
          source: res?.data?.source,
          order_paid_amount: res?.data?.total_amount,
        });
      })
      .catch((err) => {
        console.log('getManualOrder err: ', err);
      });
  };

  const getAllNotes = (id) => {
    //const id = orderId ? orderId : manualOrderId
    ApiClient.getOrderNotes(id)
      .then((res) => {
        setNotesArr(res.data.results);
        setIsNotesLoading(false);
      })
      .catch((err) => {
        console.log('getNotes error: ', err);
        setIsNotesLoading(false);
      });
  };

  const getVendorDetail = () => {
    ApiClient.getVendorDetail(id)
      .then((res) => {
        setIsLoading(false);
        setVendorOptions(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPatientDetails = (id) => {
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setPatientApiDetails(res.data);
      })
      .catch((err) => {
        console.log('getNotes error: ', err);
      });
  };

  const getSelectedOrderDetail = (id, oid) => {
    ApiClient.getSelectedOrderDetail(id, oid)
      .then((res) => {
        setSelectedOrderDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStateArrays = (medicineIndex) => {
    setOrderQuantity((prevOrderQuantity) => prevOrderQuantity.filter((_, idx) => idx !== medicineIndex));
    setOrderStatusArray((prevOrderStatusArray) => prevOrderStatusArray.filter((_, idx) => idx !== medicineIndex));
    setVendorValueArray((prevVendorValueArray) => prevVendorValueArray.filter((_, idx) => idx !== medicineIndex));
  };

  const downloadOrder = () => {
    const currentOrderId = orderId ? orderId : manualOrderId;
    ApiClient.downloadOrder(currentOrderId, { responseType: 'arraybuffer' })
      .then((res) => {
        const imageUrl = res.data.image_url;
        fetch(imageUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `order_${currentOrderId}.png`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error downloading file:', error);
          });
        // Open image in a new tab
        const newTab = window.open(imageUrl, '_blank');
        if (newTab) {
          newTab.focus();
        } else {
          console.error('Failed to open image in a new tab.');
        }
      })
      .catch((err) => {
        console.log('downloadOrder error: ', err);
      });
  };

  const formatDate = (dateString) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const formattedDate = `${day} ${months[monthIndex]} ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  };

  const handleNoteChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSaveNotes = async () => {
    if (!notes.trim()) {
      return;
    }
    const data = {
      content: notes,
      model_name: 'order',
      model_id: manualOrderId | orderId,
    };
    try {
      const response = await genericNotes({ data });
      setNotesArr([...notesArr, response.data.data]);
      //dispatch(setReduxNotesData([...notesArr, response.data.data]));
      if (response.error) {
        throw new Error(response.error);
      }
      setNotes('');
    } catch (e) {
      console.error('Payment recording failed:', e);
    }
  };

  const updateOrder = (index, medicineIndex, isFulfilledArray, index2) => {
    setIsLoading(true);
    setOrderLoadingState(medicineIndex);
    const orderItem = orderDetail[index];
    const updatedOrderDetail = orderDetail?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          tobefullfilled_orders: item[isFulfilledArray ? 'fulfilled_order' : 'tobefullfilled_orders']?.map(
            (fulfilledItem) => {
              return {
                ...fulfilledItem,
                order_items: fulfilledItem.order_items?.map((medicineItem) => {
                  const fulfilledQty = editOrderStatusArray.includes(index)
                    ? parseInt(medicineItem.to_beordered_quantity, 10) || 0
                    : medicineItem.to_beordered_quantity;
                  return {
                    ...medicineItem,
                    fulfilled_qty: fulfilledQty,
                  };
                }),
              };
            }
          ),
        };
      }
      return item;
    });

    let medicineData = updatedOrderDetail[index][
      isFulfilledArray ? 'fulfilled_order' : 'tobefullfilled_orders'
    ]?.flatMap((fulfilledItem) => fulfilledItem?.order_items[medicineIndex]);
    if (isFulfilledArray) {
      if (
        !(isFulfillOrderStatus[index2] && isFulfillOrderStatus[index2][medicineIndex]) ||
        isFulfillOrderStatus[index2][medicineIndex] == 'order_placed'
      ) {
        setFulfilledItemErrMsg((prevOrderStatusArray) => {
          const updatedOrderStatusArray = [...prevOrderStatusArray];
          updatedOrderStatusArray[index2] = 'Status cannot be order placed';
          return updatedOrderStatusArray;
        });
        return;
      } else {
        setFulfilledItemErrMsg((prevOrderStatusArray) => {
          const updatedOrderStatusArray = [...prevOrderStatusArray];
          updatedOrderStatusArray[index2] = '';
          return updatedOrderStatusArray;
        });
      }
    } else {
      if (errorMsgToBeFull[medicineIndex]) {
        setUpdateOrderErrMsg('Invalid Order Quantity');
        return;
      } else {
        setUpdateOrderErrMsg('');
      }
    }
    const params = {
      order_id: orderItem.order_id,
      ehr_order_status: orderStatusArray[index] || orderItem.ehr_order_status,
      item_details: [
        {
          id: medicineData[index2 || 0].id,
          to_beordered_quantity: isFulfilledArray
            ? medicineData[index2 || 0].to_beordered_quantity
            : orderQuantity[medicineIndex] || medicineData[index2 || 0].to_beordered_quantity,
          quantity: medicineData[index2 || 0].quantity,
          item_amount: medicineData[index2 || 0].item_amount,
          item_mrp: medicineData[index2 || 0].item_mrp,
          product_item: { id: medicineData[index2 || 0].product_item.id },
          vendor: isFulfilledArray
            ? medicineData[index2 || 0].vendor
            : vendorValueArray[medicineIndex] || medicineData[index2 || 0].vendor,
          item_status:
            (isFulfilledArray ? isFulfillOrderStatus[index2][medicineIndex] : orderStatusArray[medicineIndex]) ||
            medicineData[index2 || 0].item_status,
        },
      ],
    };
    ApiClient.updateOrderDetail(props?.order && orderDetail[index]?.user?.id, params)
      .then((res) => {
        setIsLoading(false);
        props?.order && props?.searchOrder(index);
        setAlertError('Order updated successfully');
        setAlertType('alert-success');
        setPendingItemErrorMsg('');
        setEditOrderStatusArray([]);
        setAlertMessage('Order updated successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        props?.order && props?.searchOrder(index);
        setPendingItemErrorMsg(err?.response?.data?.errors);
        setEditOrderStatusArray([]);
        if (err?.response?.data?.msg) {
          setAlertError(err.response.data.msg);
          setAlertType('alert-danger');
        }
        console.log(err);
      })
      .finally(() => {});
    updateStateArrays(medicineIndex);
  };

  const orderStatus = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
    {
      title: 'Partially Ordered',
      id: 'ordered_partially',
    },
    {
      title: 'Unfulfilled',
      id: 'in_progress',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
  ];

  const orderStatusDropdownOptions = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
  ];

  const fulfilledDropdownOptions = [
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
  ];

  const handleStatusItemClick = (index, statusId, isFullfilledArray, medicineIndex) => {
    if (isFullfilledArray) {
      setIsFulfillOrderStatus((prevOrderStatusArray) => {
        const updatedOrderStatusArray = [...prevOrderStatusArray];
        if (!updatedOrderStatusArray[medicineIndex]) {
          updatedOrderStatusArray[medicineIndex] = [];
        }
        updatedOrderStatusArray[medicineIndex][index] = statusId;
        return updatedOrderStatusArray;
      });
      setIsDropdownOpen((prevVendorValueArray) => {
        const updatedOrderStatusArray = [...prevVendorValueArray];
        if (!updatedOrderStatusArray[medicineIndex]) {
          updatedOrderStatusArray[medicineIndex] = [];
        }
        updatedOrderStatusArray[medicineIndex][index] =
          isDropdownOpen[medicineIndex] && isDropdownOpen[medicineIndex][index] != undefined
            ? isDropdownOpen[medicineIndex][index] === index
              ? false
              : index
            : index;
        return updatedOrderStatusArray;
      });
    } else {
      setOrderStatusArray((prevOrderStatusArray) => {
        const updatedOrderStatusArray = [...prevOrderStatusArray];
        updatedOrderStatusArray[index] = statusId;
        return updatedOrderStatusArray;
      });
      setEditOrderStatusArray([index]);
      setClickDropdown((prevClickDropdown) => (prevClickDropdown === index ? false : index));
    }
  };

  const orderStatusWithColor = (item, index, isHeading) => {
    return (
      <div className={`d-flex align-items-center ${isHeading && `justify-content-end`}`}>
        <span
          className={`status-text align-items-center badge capitalize active align-items-center d-flex ${
            isHeading ? item?.ehr_order_status : orderStatusArray[index] || item.item_status
          }`}
        >
          {isHeading
            ? titlizeString(orderStatus.find((item3) => item3.id == item?.ehr_order_status)?.title) || item.item_status
            : titlizeString(orderStatus.find((item3) => item3.id == orderStatusArray[index])?.title) ||
              titlizeString(orderStatus.find((item3) => item3.id == item.ehr_order_status)?.title) ||
              item.item_status}
        </span>
      </div>
    );
  };

  const renderOrderSummary = (order, refundTotal) => {
    return (
      <>
        <table className="table table-suborder" style={{ border: `1px solid #D2D2D2`, borderRadius: '5px' }}>
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Product Name</th>
              <th>SKU</th>
              <th>Ordered Quantity</th>
              <th>Fullfilled Quantity</th>
            </tr>
          </thead>
          <tbody>
            {order &&
              Object.values(order)?.map((medicineItem, medicineIndex) => {
                const defaultVendor = medicineItem.vendor; // Outer "vendor" key as default
                const defaultVendorPrice = medicineItem?.product_item?.product_price?.find(
                  (price) => price?.vendor?.id === defaultVendor?.id
                );
                const elevateNowVendor = medicineItem?.product_item?.product_price?.find(
                  (item) => item?.vendor?.vendor_name === 'ElevateNow'
                );
                return (
                  <tr>
                    <td>
                      <b>{medicineIndex + 1}</b>
                    </td>
                    <td>{medicineItem?.item?.product?.product_name}</td>
                    <td>
                      <b>{defaultVendorPrice?.sku || medicineItem?.product_item?.sku || elevateNowVendor?.sku}</b>
                    </td>
                    <td>{medicineItem?.fulfilled_qty}</td>
                    <td>{medicineItem?.ordered_quantity}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  };

  const renderOrderStatusDropdown = (item, index, isFulfilledArray, index2) => {
    let status =
      isFulfillOrderStatus[index2] && isFulfillOrderStatus[index2][index] && isFulfillOrderStatus[index2][index];
    let dropdown =
      isDropdownOpen[index2] && isDropdownOpen[index2][index] != undefined ? isDropdownOpen[index2][index] : false;
    return (
      <div className={`d-flex justify-content-center align-items-center`}>
        <div className="patientordertab">
          <div className="order-dropdown-part">
            <span
              onClick={() => {
                handleStatusItemClick(
                  index,
                  (isFulfilledArray ? status : orderStatusArray[index]) || item.item_status,
                  isFulfilledArray,
                  index2
                );
              }}
              className={`status-text align-items-center badge capitalize active align-items-center d-flex ${
                (isFulfilledArray ? status : orderStatusArray[index]) || item.item_status
              }`}
            >
              {titlizeString(
                orderStatus.find((item3) => item3.id == (isFulfilledArray ? status : orderStatusArray[index]))?.title
              ) ||
                titlizeString(orderStatus.find((item3) => item3.id == item.ehr_order_status)?.title) ||
                titlizeString(orderStatus.find((item3) => item3.id == item.item_status)?.title) ||
                item.item_status}
            </span>
          </div>
          <div
            className={`order-dropdown  ${(isFulfilledArray ? dropdown : clickDropdown) === index ? 'orderList' : ''}`}
          >
            <ul>
              {(isFulfilledArray ? fulfilledDropdownOptions : orderStatusDropdownOptions)?.map((statusItem) => (
                <li key={statusItem.id}>
                  <span
                    className=""
                    onClick={() => handleStatusItemClick(index, statusItem.id, isFulfilledArray, index2)}
                  >
                    {statusItem.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const showEditIcon = (index, medicineIndex, index2) => {
    return (
      // return isOrderStatusEditable &&
      //   isOrderStatusEditable[medicineIndex] &&
      //   isOrderStatusEditable[medicineIndex][index2] ? (
      <>
        <button
          style={{ backgroundColor: '#fff' }}
          onClick={() => {
            updateOrder(index, index2, true, medicineIndex);
            setUpdateClicked(true); // Set the flag when the update button is clicked
          }}
          disabled={isDropdownOpen[medicineIndex]?.[index2] == undefined} // Disable the button if the status hasn't changed, new code
        >
          {isDropdownOpen[medicineIndex]?.[index2] == undefined ? (
            <img src={checkCircleInactive} class="pointer" />
          ) : (
            <img src={checkCircle} class="pointer" />
          )}
        </button>{' '}
        <button
          style={{ backgroundColor: '#fff' }}
          onClick={() => {
            setIsOrderStatusEditable((isEditable) => {
              const updatedOrderStatusArray = [...isEditable];
              if (!updatedOrderStatusArray[medicineIndex]) {
                updatedOrderStatusArray[medicineIndex] = [];
              }
              updatedOrderStatusArray[medicineIndex][index2] = !(
                isOrderStatusEditable[medicineIndex] && isOrderStatusEditable[medicineIndex][index2]
              );
              return updatedOrderStatusArray;
            });
          }}
          disabled={isDropdownOpen[medicineIndex]?.[index2] == undefined}
        >
          {isDropdownOpen[medicineIndex]?.[index2] == undefined ? (
            <img src={cancelCircleInactive} class="pointer" />
          ) : (
            <img src={cancelCircle} class="pointer" />
          )}
        </button>{' '}
      </>
    );
  };

  const renderOrders = (item, index) => {
    return (
      <>
        <div style={{ marginTop: '5px' }}>
          <span
            style={{
              fontSize: '12px',
              color: '#848484',
              fontWeight: '500',
              textDecorationColor: '#757575',
            }}
          >
            Created On: {dateFormat(item?.created_at)}, {timeFormat(item?.created_at)}
          </span>
        </div>

        <div className=" d-flex justify-content-between" style={{ width: '100%' }}>
          <p style={{ position: 'absolute' }}>{item?.ehr_order_status && orderStatusWithColor(item, index, true)}</p>
          <div className="d-flex flex-col justify-content-between items-center space-x-4 order-modal">
            <div
              className="d-flex align-items-center"
              style={{ background: '#F9FAFE', padding: '10px', borderRadius: '8px', width: '50%' }}
            >
              <div className="mr-2 mt-4 text-capitalize" style={{ fontWeight: '500' }}>
                <p>Source: {orderSummary.source}</p>
                <p>Order ID : {item?.order_id}</p>
              </div>
              <div className="vl">.</div>
              <div className="mx-2 patient-name" style={{ fontWeight: 'bold', color: '#243b86' }}>
                <img
                  className="listUserImage rounded-circle"
                  style={{ width: '20px', height: '20px', marginRight: '5px' }}
                  src={profileImage(patientApiDetails?.gender, patientApiDetails?.profile_url)}
                />
                {patientApiDetails?.full_name}
              </div>
              <div className="mx-2 patient-address d-flex">
                <div>
                  <img src={location} alt="location" />
                </div>

                <div className="complete-address" style={{ fontWeight: '500' }}>
                  {/* {selectedOrderDetail?.shipping_address
                    ? selectedOrderDetail?.shipping_address
                    : patientApiDetails?.address} */}
                  {selectedOrderDetail?.shipping_address
                    ? selectedOrderDetail?.shipping_address
                    : item?.shipping_address}
                </div>
              </div>
            </div>
            <div style={{ marginRight: '20px' }}>
              <div
                onClick={() => {
                  setEditMode(true);
                }}
                className="reusableBtnActive  reusableBtn "
                style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
              >
                {/* <img src={coupons} alt='Coupons' /> */}
                View Prescription
              </div>
              <Modal
                backdrop="static"
                size="md"
                open={isEditMode}
                onClose={() => {
                  setEditMode(false);
                }}
              >
                <Modal.Header>
                  <div className="settingFormOutline p-0">
                    <div className="formTitle mb-0">Prescription</div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <PatientPrescription patientId={item?.user?.id} />
                  <div>{/* {rescheduleHistoryElements()} */}</div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>

        {item?.fulfilled_order?.map((item2, index2) => {
          return (
            <div className=" diet-regime cardDataBorder w-100 patientordertabtable fulfilled-suborder">
              {fulfilledItemErrMsg[index2] && <span className="requiredInput"> {fulfilledItemErrMsg[index2]}</span>}
              <div
                className="table-suborder"
                style={item2?.vendor?.vendor_name === 'WareIQ' ? { overflowX: 'scroll' } : {}}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="table-heading d-flex justify-content-between  align-items-center mb-3">
                    <div class="d-flex">
                      <div>
                        <b>
                          Sub-Order {index2 + 1}:{' '}
                          {item2?.vendor?.vendor_logo && (
                            <img
                              src={item2?.vendor?.vendor_logo}
                              height="15px"
                              width="15px"
                              style={{ margin: '-2px 4px 0 3px', objectFit: 'contain' }}
                            />
                          )}
                          {item2?.vendor?.vendor_name}
                        </b>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, padding: '20px' }}>
                    {item2?.order_items &&
                      item2?.order_items.length > 0 &&
                      item2?.order_items[0]?.payout_id !== null && (
                        <div
                          onClick={() => {
                            navigate(`/orders/${orderId}/${patientId}/view-payout/${item2?.order_items[0]?.payout_id}`);
                          }}
                          className="reusableBtnActive  reusableBtn"
                          style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                        >
                          View Payout
                        </div>
                      )}
                    {item2?.order_items &&
                      item2?.order_items.length > 0 &&
                      item2?.order_items[0]?.invoice_id &&
                      !(
                        item2?.order_items &&
                        item2?.order_items.length > 0 &&
                        item2?.order_items[0]?.payout_id !== null
                      ) && (
                        <div
                          onClick={() => {
                            setPayoutData({
                              invoiceId: item2?.order_items[0]?.invoice_id,
                              vendorName: item2?.vendor?.vendor_name,
                            });
                          }}
                          className="reusableBtnActive  reusableBtn"
                          style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                          data-bs-toggle={'offcanvas'}
                          data-bs-target={'#payoutModal'}
                        >
                          Generate Payout
                        </div>
                      )}
                    {item2?.order_items && item2?.order_items.length > 0 && item2?.order_items[0]?.invoice_id && (
                      <div
                        onClick={() => {
                          navigate(`/orders/${orderId}/${patientId}/view-invoice/${item2?.vendor?.id}`);
                        }}
                        className="reusableBtnActive  reusableBtn"
                        style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                      >
                        View Invoice
                      </div>
                    )}
                    {item2?.order_items && item2?.order_items.length > 0 && !item2?.order_items[0]?.invoice_id && (
                      <div
                        onClick={() => {
                          setVendorOrder({
                            vendorOrderDetails: item2,
                            orderId: item.id,
                          });
                        }}
                        className="reusableBtnActive  reusableBtn"
                        style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                        data-bs-toggle={'offcanvas'}
                        data-bs-target={'#invoiceModal'}
                      >
                        Generate Invoice
                      </div>
                    )}
                  </div>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      {item2?.vendor?.vendor_name === 'WareIQ' && <th className="w-100">Invoice Id</th>}
                      {item2?.vendor?.vendor_name === 'WareIQ' && <th className="w-100">Ware IQ Id</th>}
                      <th>Medicine</th>
                      <th>SKU</th>
                      <th>Vendor</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Disc. Price</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item2?.order_items?.map((medicineItem, medicineIndex) => {
                      const errorMsgIndex = `${index}-${medicineIndex}`;
                      const defaultVendor = medicineItem?.vendor; // Outer "vendor" key as default
                      const defaultVendorPrice = medicineItem?.product_item?.product_price?.find(
                        (price) => price.vendor?.id === defaultVendor?.id
                      );
                      const elevateNowVendor = medicineItem?.product_item?.product_price?.find(
                        (item) => item?.vendor?.vendor_name === 'ElevateNow'
                      );

                      return (
                        <tr>
                          <td>
                            <b>{medicineIndex + 1}</b>
                          </td>
                          {item2?.vendor?.vendor_name === 'WareIQ' && (
                            <td style={{ minWidth: '100px' }}>
                              <b>{medicineItem?.invoice_id || 'N/A'}</b>
                            </td>
                          )}
                          {item2?.vendor?.vendor_name === 'WareIQ' && (
                            <td style={{ minWidth: '100px' }}>
                              <b>{medicineItem?.wiq_order_id || 'N/A'}</b>
                            </td>
                          )}
                          <td className="capitalize">
                            <b>
                              {medicineItem?.product_item?.product?.medicine?.formulation}&nbsp;
                              {medicineItem.product_item.product.product_name}
                            </b>
                          </td>
                          <td style={{ minWidth: '170px' }}>
                            <b>{defaultVendorPrice?.sku || medicineItem?.product_item?.sku || elevateNowVendor?.sku}</b>
                          </td>
                          <td>{medicineItem?.vendor?.vendor_name}</td>
                          <td>
                            <div className="w-60" style={{ width: '60px' }}>
                              {item.fulfilled_order[index2].order_items[medicineIndex].fulfilled_qty}
                            </div>
                          </td>
                          <td>Rs.{parseFloat(medicineItem?.item_mrp).toFixed(2)}</td>
                          <td>
                            Rs.
                            {parseFloat(medicineItem?.item_amount).toFixed(2)}
                            {/* {(
                              parseFloat(medicineItem?.item_amount) *
                              (1 - parseFloat(medicineItem?.discount_percentage) / 100)
                            ).toFixed(2)} */}
                          </td>
                          <td>Rs.{parseFloat(medicineItem.fulfilled_qty * medicineItem.item_amount).toFixed(2)} </td>
                          <td>{renderOrderStatusDropdown(medicineItem, medicineIndex, true, index2)}</td>
                          <td style={{ minWidth: '75px' }}>
                            {medicineItem.item_status != 'delivered' ? showEditIcon(index, index2, medicineIndex) : ''}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}

        {item?.unfulfilled_order?.length > 0 ? (
          <>
            <div className="mt-3">Unfulfilled Orders</div>
          </>
        ) : null}

        {item?.unfulfilled_order?.map((item2, index2) => {
          return (
            <div className=" diet-regime cardDataBorder w-100">
              <table className="table table-suborder" style={{ border: '1px solid #D2D2D2', borderRadius: '5px' }}>
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Medicine</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Disc. Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {item2?.order_items?.map((medicineItem, medicineIndex) => {
                    const errorMsgIndex = `${index}-${medicineIndex}`;
                    const elevateNowVendor = medicineItem?.product_item?.product_price?.find(
                      (item) => item?.vendor?.vendor_name === 'ElevateNow'
                    );
                    return (
                      <tr>
                        <td>
                          <b>{medicineIndex + 1}</b>
                        </td>
                        <td className="capitalize">
                          <b>
                            {medicineItem?.product_item?.product?.medicine?.formulation}&nbsp;
                            {medicineItem.product_item.product.product_name}
                          </b>
                        </td>
                        <td>
                          <b>{elevateNowVendor?.sku}</b>
                        </td>
                        <td>{medicineItem.quantity}</td>
                        <td>Rs. {medicineItem?.item_mrp}</td>
                        <td>
                          Rs.
                          {medicineItem?.item_amount}
                          {/* {(
                            parseFloat(medicineItem?.item_amount) *
                            (1 - parseFloat(medicineItem?.discount_percentage) / 100)
                          ).toFixed(2)} */}
                        </td>
                        <td>
                          {' '}
                          <div className={`d-flex justify-content-center align-items-center`}>
                            <span
                              className={`status-text align-items-center badge capitalize active align-items-center d-flex unfulfilled-status ${
                                medicineItem?.item_status === 'cancelled' && medicineItem?.item_status
                              }
                              }`}
                            >
                              {medicineItem?.item_status === 'cancelled' ? 'Cancelled' : 'Unfulfilled'}
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}

        {item?.tobefullfilled_orders?.length > 0 ? (
          <>
            <div className="mt-3">Pending Items</div>
          </>
        ) : null}

        {(pendingItemErrorMsg || updateOrderErrMsg) && (
          <span className="requiredInput"> {pendingItemErrorMsg || updateOrderErrMsg}</span>
        )}

        {item &&
          item?.tobefullfilled_orders &&
          item?.tobefullfilled_orders?.map((item2, index2) => {
            return (
              <div className={`diet-regime cardDataBorder patientordertabtable w-95`}>
                <table
                  className="table"
                  style={{
                    width: '100% !important',
                    borderTop: '1px solid #E7E7E7',
                    borderBottom: '1px solid #E7E7E7',
                    borderRadius: '5px',
                  }}
                >
                  <thead>
                    {item.order_status === 'draft' && (
                      <tr style={{ borderBottom: '1px solid black' }}>
                        <th style={{ paddingBottom: 8, textAlign: 'left', width: 100, background: '#D9D9D940' }}>
                          Draft Order
                        </th>
                        {/* <th colSpan="8" style={{ textAlign: 'right'}}><img src={editIcon} class='pointer' /></th> */}
                      </tr>
                    )}
                    <tr>
                      <th>Sr.No.</th>
                      <th>Medicine</th>
                      <th>SKU</th>
                      <th>Vendor</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Disc. Price</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item2?.order_items?.map((medicineItem, medicineIndex) => {
                      const errorMsgIndex = `${index}-${medicineIndex}`;
                      const defaultVendor = medicineItem?.vendor; // Outer "vendor" key as default
                      const defaultVendorPrice = medicineItem?.product_item?.product_price?.find(
                        (price) => price.vendor?.id === defaultVendor?.id
                      );
                      return (
                        <tr>
                          <td>
                            <b>{medicineIndex + 1}</b>
                          </td>
                          <td className="capitalize">
                            <b>
                              {medicineItem?.product_item?.product?.medicine?.formulation}&nbsp;
                              {medicineItem.product_item.product.product_name}
                            </b>
                          </td>
                          {/* <td>
                            <b>{medicineItem?.product_item?.product_price[0].sku}</b>
                          </td> */}
                          <td>
                            <b>{defaultVendorPrice?.sku || medicineItem?.product_item?.sku}</b>
                          </td>
                          <td>
                            {medicineItem?.vendor?.vendor_name === '1MG' ? (
                              <b>{medicineItem?.vendor?.vendor_name}</b>
                            ) : (
                              <Dropdown
                                className="capitalize pointer"
                                title={vendorValueArray[medicineIndex] || 'Select'}
                              >
                                {venderOptions?.map((item, index3) => (
                                  <Dropdown.Item
                                    key={index3}
                                    onClick={() => {
                                      setVendorValueArray((prevVendorValueArray) => {
                                        const updatedArray = [...prevVendorValueArray];
                                        updatedArray[medicineIndex] = item.vendor_name;
                                        return updatedArray;
                                      });
                                    }}
                                  >
                                    {item?.vendor_logo && (
                                      <img
                                        src={item.vendor_logo}
                                        height="15px"
                                        width="15px"
                                        style={{ margin: '-2px 4px 0 3px', objectFit: 'contain' }}
                                        alt={item.vendor_name}
                                      />
                                    )}{' '}
                                    {item?.vendor_name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown>
                            )}
                          </td>
                          <td>
                            {/* {editOrderStatusArray.includes(index) ? ( */}
                            <div className="w-60" style={{ display: 'flex', flexDirection: 'column' }}>
                              {/* {isEditable && isEditable[medicineIndex] ? ( */}
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <button
                                  disabled={medicineItem?.vendor?.vendor_name === '1MG'}
                                  onClick={(e) => {
                                    let updatedQuantity =
                                      (orderQuantity[medicineIndex] != undefined
                                        ? orderQuantity[medicineIndex]
                                        : item?.tobefullfilled_orders[index2]?.order_items[medicineIndex]
                                            ?.to_beordered_quantity) - 1;
                                    setOrderQuantity((prevVendorValueArray) => {
                                      const updatedOrderStatusArray = [...prevVendorValueArray];
                                      updatedOrderStatusArray[medicineIndex] = updatedQuantity;
                                      prevVendorValueArray[medicineIndex] = updatedQuantity;
                                      return updatedOrderStatusArray;
                                    });
                                    const originalQty = medicineItem.quantity;
                                    if (updatedQuantity > 0 && updatedQuantity <= originalQty) {
                                      //setQuantityVal(updatedQuantity);
                                      setErrorMsgToBeFull((prevErrorMsg) => {
                                        const updatedErrorMsg = { ...prevErrorMsg };
                                        updatedErrorMsg[medicineIndex] = '';
                                        return updatedErrorMsg;
                                      });
                                    } else if (updatedQuantity <= 0) {
                                      setErrorMsgToBeFull((prevErrorMsg) => {
                                        const updatedErrorMsg = { ...prevErrorMsg };
                                        updatedErrorMsg[medicineIndex] =
                                          'Invalid quantity. Please enter a value greater than 0';
                                        return updatedErrorMsg;
                                      });
                                    } else {
                                      setErrorMsgToBeFull((prevErrorMsg) => {
                                        const updatedErrorMsg = { ...prevErrorMsg };
                                        updatedErrorMsg[medicineIndex] =
                                          'Invalid quantity. Please enter a value less than or equal to the original quantity.';
                                        return updatedErrorMsg;
                                      });
                                    }
                                  }}
                                  style={{
                                    padding: '0px 10px 0 10px',
                                    height: '34px',
                                    borderRadius: '5px',
                                    marginRight: '-4px',
                                    zIndex: 2,
                                    backgroundColor: '#193990',
                                    color: '#fff',
                                  }}
                                >
                                  -
                                </button>
                                <div style={{ width: '50px', display: 'flex', flexDirection: 'column' }}>
                                  <CoreInputBox
                                    disabled={true}
                                    inputStyle={{ borderRaius: '0 !important', textAlign: 'center' }}
                                    showMSG={errorMsgToBeFull[medicineIndex]}
                                    value={
                                      orderQuantity[medicineIndex] != undefined
                                        ? orderQuantity[medicineIndex]
                                        : item?.tobefullfilled_orders[index2]?.order_items[medicineIndex]
                                            ?.to_beordered_quantity
                                    }
                                    ///value={item.fulfilled_order[index2].order_items[medicineIndex].to_beordered_quantity}
                                    setValue={(data) => {
                                      const updatedQty = parseInt(data, 10) || 0;
                                      const originalQty = medicineItem.quantity;
                                      setOrderQuantity((prevVendorValueArray) => {
                                        const updatedOrderStatusArray = [...prevVendorValueArray];
                                        updatedOrderStatusArray[medicineIndex] = updatedQty;
                                        prevVendorValueArray[medicineIndex] = updatedQty;
                                        return updatedOrderStatusArray;
                                      });
                                      if (updatedQty <= originalQty && updatedQty > 0) {
                                        setErrorMsgToBeFull((prevErrorMsg) => {
                                          const updatedErrorMsg = { ...prevErrorMsg };
                                          updatedErrorMsg[medicineIndex] = '';
                                          return updatedErrorMsg;
                                        });
                                      } else {
                                        setErrorMsgToBeFull((prevErrorMsg) => {
                                          const updatedErrorMsg = { ...prevErrorMsg };
                                          updatedErrorMsg[medicineIndex] =
                                            'Invalid quantity. Please enter a value less than or equal to the original quantity.';
                                          return updatedErrorMsg;
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <button
                                  disabled={medicineItem?.vendor?.vendor_name === '1MG'}
                                  onClick={(e) => {
                                    let updatedQuantity =
                                      (orderQuantity[medicineIndex] != undefined
                                        ? orderQuantity[medicineIndex]
                                        : item?.tobefullfilled_orders[index2]?.order_items[medicineIndex]
                                            ?.to_beordered_quantity) + 1;
                                    setOrderQuantity((prevVendorValueArray) => {
                                      const updatedOrderStatusArray = [...prevVendorValueArray];
                                      updatedOrderStatusArray[medicineIndex] = updatedQuantity;
                                      prevVendorValueArray[medicineIndex] = updatedQuantity;
                                      return updatedOrderStatusArray;
                                    });
                                    const originalQty = medicineItem.quantity;
                                    if (updatedQuantity <= originalQty && updatedQuantity > 0) {
                                      //setQuantityVal(updatedQuantity);
                                      setErrorMsgToBeFull((prevErrorMsg) => {
                                        const updatedErrorMsg = { ...prevErrorMsg };
                                        updatedErrorMsg[medicineIndex] = '';
                                        return updatedErrorMsg;
                                      });
                                    } else {
                                      setErrorMsgToBeFull((prevErrorMsg) => {
                                        const updatedErrorMsg = { ...prevErrorMsg };
                                        updatedErrorMsg[medicineIndex] =
                                          'Invalid quantity. Please enter a value less than or equal to the original quantity.';
                                        return updatedErrorMsg;
                                      });
                                    }
                                  }}
                                  style={{
                                    padding: '0px 10px 0 10px',
                                    height: '34px',
                                    borderRadius: '5px',
                                    marginLeft: '-4px',
                                    zIndex: 2,
                                    backgroundColor: '#919191',
                                    color: '#fff',
                                  }}
                                >
                                  +
                                </button>
                              </div>
                              {/* // ) : (
                              //   item?.tobefullfilled_orders[index2]?.order_items[medicineIndex]?.to_beordered_quantity
                              // )} */}
                            </div>
                          </td>
                          <td>Rs.{parseFloat(medicineItem?.item_mrp).toFixed(2)}</td>
                          <td>
                            Rs.
                            {parseFloat(medicineItem?.item_amount).toFixed(2)}
                          </td>
                          <td>
                            Rs.
                            {(parseFloat(medicineItem.fulfilled_qty) * parseFloat(medicineItem?.item_amount)).toFixed(
                              2
                            )}
                          </td>
                          <td style={{ pointerEvents: medicineItem?.vendor?.vendor_name === '1MG' ? 'none' : 'auto' }}>
                            {isLoading || (screenLoading && orderLoadingState === medicineIndex) ? (
                              <div style={{ borderRadius: 8, padding: 2, fontSize: '10px' }}>Updating</div>
                            ) : (
                              renderOrderStatusDropdown(medicineItem, medicineIndex)
                            )}
                          </td>
                          <td>
                            {
                              // showUpdateIcon[medicineIndex]
                              // isEditable && isEditable[medicineIndex] ? (
                              <>
                                <button
                                  style={{ backgroundColor: '#fff' }}
                                  onClick={() => {
                                    updateOrder(index, medicineIndex);
                                    setUpdateClicked(true);
                                  }}
                                  disabled={
                                    !vendorValueArray[medicineIndex] &&
                                    orderStatusArray[medicineIndex] !== 'cancelled' &&
                                    orderStatusArray[medicineIndex] === undefined
                                  }
                                >
                                  {orderStatusArray[medicineIndex] === 'cancelled' ? (
                                    <img src={checkCircle} className="pointer" />
                                  ) : orderStatusArray[medicineIndex] === undefined ? (
                                    <img src={checkCircleInactive} className="pointer" />
                                  ) : (
                                    <img src={checkCircle} className="pointer" />
                                  )}
                                </button>{' '}
                                <button
                                  style={{ backgroundColor: '#fff' }}
                                  onClick={() => {
                                    isEditable &&
                                      setIsEditable((isEditable) => {
                                        const updatedOrderStatusArray = [...isEditable];
                                        updatedOrderStatusArray[medicineIndex] = !isEditable[medicineIndex];
                                        return updatedOrderStatusArray;
                                      });
                                    setUpdateClicked(false);
                                    setVendorValueArray((prevVendorValueArray) => {
                                      const updatedOrderVendorArray = [...prevVendorValueArray];
                                      updatedOrderVendorArray[medicineIndex] = null;
                                      prevVendorValueArray[medicineIndex] = null;
                                      return updatedOrderVendorArray;
                                    });
                                  }}
                                  disabled={
                                    !vendorValueArray[medicineIndex] &&
                                    orderStatusArray[medicineIndex] !== 'cancelled' &&
                                    orderStatusArray[medicineIndex] === undefined
                                  }
                                >
                                  {orderStatusArray[medicineIndex] === 'cancelled' ? (
                                    <img src={cancelCircle} className="pointer" />
                                  ) : orderStatusArray[medicineIndex] === undefined ? (
                                    <img src={cancelCircleInactive} className="pointer" />
                                  ) : (
                                    <img src={cancelCircle} className="pointer" />
                                  )}
                                </button>{' '}
                              </>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ marginRight: '20px' }} className="d-flex justify-content-end"></div>
              </div>
            );
          })}
        <div style={{ marginRight: '0px' }} className="row justify-content-end">
          <div className="col-lg-4">
            <div className="order-summary p-3 bg-light rounded " style={{ marginRight: '50px' }}>
              <h6 className="fw-bold mb-3">Order Summary</h6>
              <div className="d-flex justify-content-between mb-2">
                <div>Order Total</div>
                <div>Rs. {orderSummary?.order_total?.toFixed(2)}</div>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Discount</div>
                {/* <div>{orderSummary.discount_percentage?.toFixed(2)}%</div> */}
                {/* {`(${Math.round((orderSummary.discount / orderSummary.orderTotal) * 100)}%)`} */}
                <div>
                  -Rs. {orderSummary.discount} {`(${orderSummary?.discount_percentage}%)`}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Shipping</div>
                <div>{orderSummary.shipping_amount == '0' ? 'Free' : orderSummary.shipping_amount}</div>
              </div>
              <div className="hrLine mb-3" />
              <div className="d-flex justify-content-between fw-bold">
                <div>Total</div>

                <div>
                  {/* Rs. {(orderSummary?.order_total - orderSummary?.discount + orderSummary.shipping_amount).toFixed(2)}{' '} */}
                  Rs. {orderSummary?.order_paid_amount}
                </div>
              </div>
              <div className="hrLine mb-3" />
            </div>
          </div>
        </div>
        <div className="hrLine mb-3" style={{ background: '#D1DCFF', marginRight: '50px' }} />

        <div className="row">
          <div className="col-lg-12">
            <div className="notes-section mt-3">
              <label className="fw-bold">Notes</label>
              <div className="textarea-container">
                <textarea value={notes} onChange={handleNoteChange} className="form-control mb-3" />
                <button className="save-button" onClick={handleSaveNotes}>
                  <img src={noteSave} alt="Save Note" />
                </button>
              </div>
              {/* {notesArr.length > 0 ? (
              notesArr
                .slice()
                .reverse()
                .map((note) => (
                  <div key={note?.id} className="note-container mt-2">
                    <div className="mr-2" style={{ marginRight: "10px" }}>
                      <img
                        src={profileImage(
                          patientDetails.gender,
                          userData?.profile_url
                        )}
                        style={{ height: 40 }}
                        alt="Profile"
                      />
                    </div>
                    <div className="note">
                      <div className="note-time">
                        {formatDate(note?.updated_at)}
                      </div>
                      <div className="note-content">{note?.content}</div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="note-container">
                <div className="mr-2" style={{ marginRight: "10px" }}>
                  <img
                    src={profileImage(patientDetails.gender, userData?.profile_url)}
                    style={{ height: 40 }}
                    alt="Avatar"
                  />
                </div>
                <div className="note" style={{ height: "40px" }}>
                  <div className="note-time"></div>
                  <div className="note-content"></div>
                </div>
              </div>
            )} */}
              {notesArr.length > 0 ? (
                notesArr
                  .slice()
                  .reverse()
                  .map((note) => (
                    <div key={note?.id} className="note-container mt-2">
                      <div className="mr-2" style={{ marginRight: '10px' }}>
                        <img
                          src={profileImage(patientDetails.gender, userData?.profile_url)}
                          style={{ height: 40 }}
                          alt="Profile"
                        />
                      </div>
                      <div className="note">
                        <div className="note-time">{formatDate(note?.updated_at)}</div>
                        <div className="note-content">{note?.content}</div>
                      </div>
                    </div>
                  ))
              ) : reduxNotesData.length > 0 ? (
                reduxNotesData
                  .slice()
                  .reverse()
                  .map((note) => (
                    <div key={note?.id} className="note-container mt-2">
                      <div className="mr-2" style={{ marginRight: '10px' }}>
                        <img
                          src={profileImage(patientDetails.gender, userData?.profile_url)}
                          style={{ height: 40 }}
                          alt="Profile"
                        />
                      </div>
                      <div className="note">
                        <div className="note-time">{formatDate(note?.updated_at)}</div>
                        <div className="note-content">{note?.content}</div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="note-container">
                  <div className="mr-2" style={{ marginRight: '10px' }}>
                    <img
                      src={profileImage(patientDetails.gender, userData?.profile_url)}
                      style={{ height: 40 }}
                      alt="Avatar"
                    />
                  </div>
                  <div className="note" style={{ height: '40px' }}>
                    <div className="note-time"></div>
                    <div className="note-content"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSearchBox = () => {
    return (
      <div className="w-100">
        <CoreInputBox
          placeholder="Search a Order by Patient name or Order ID"
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const showTree1 = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      title: patientApiDetails.full_name, // patientDetails.full_name,
      icon: profileImage(
        patientApiDetails.gender || patientDetails.gender,
        patientApiDetails.profile_url || patientDetails.profile_url
      ),
      link: `/patient/${patientId || id}/dashboard`,
    },
    {
      title: 'View Order',
      icon: '',
      link: '',
    },
  ];

  const renderHeader = () => {
    return (
      <div className="headerFiles">
        <AdminPanelHeader hideSearch iconTitle="shopping_cart" title="Orders" searchBox={renderSearchBox()} />
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="headerFiles">
        <AdminPanelHeader
          searchBox={renderSearchBox}
          iconTitle="person_outline"
          showTree={showTree1}
          title="Patient"
          placeholder="Search Patients"
        />
      </div>
    );
  };

  return (
    <>
      <div className="articleHeader stickyHeader">
        {uselocation.pathname.includes(`/patient/${props.patientId}/dashboard/orders`)
          ? renderHeader()
          : renderHeader1()}
        <div className="hrLine mb-3" />
        {/* <RowLinks data={headerData} /> */}
      </div>
      <div className="wrapperContent">
        <CommonAlert
          isShowAlert={alertError}
          message={alertMessage}
          alertType={alertType}
          setIsShowAlert={setAlertError}
          className="mt-3"
        />
      </div>
      <div ref={containerRef}>
        {!index && (
          <div className="d-flex justify-content-center mt-5 mb-2">
            {orderDetail?.length && isLoading ? '' : 'No data found'}
          </div>
        )}

        <div style={{ marginLeft: '230px' }} className="wrapperContent">
          {props?.order && renderOrders(orderDetail && orderDetail[index], index)}
        </div>
        <Loader show={isLoading} />
      </div>
      <div className="form-buttons">
        <button
          style={{
            backgroundColor: '#fff',
            color: '#243B86',
            border: '1px solid #243B86',
            borderRadius: '8px',
            padding: '10px',
            marginRight: '10px',
            fontWeight: '600',
          }}
          onClick={downloadOrder}
        >
          <img style={{ paddingRight: '10px' }} src={downloadIcon} />
          Download
        </button>
      </div>

      <InvoiceModal
        vendorOrder={vendorOrder}
        isEditMode={false}
        callOrders={() => props?.order && props?.searchOrder(index)}
      />
      <PayoutModal payoutData={payoutData} callOrders={() => props?.order && props?.searchOrder(index)} />
      <ToastContainer />
    </>
  );
}

export default RenderApolloTab;
