import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { CloseRound, searchTheme } from 'res/images';
import { clickOnElmOnBodyClick, titlizeString } from './Helper';
import { TagsShow, ChipsTagsView, CoreInputBox, CoreSearchDropDown } from './index';
import labels from '../../inputFields.json';

function AuthorFilter(props) {
  const { filterData, setFilterData, api, title } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let stringActiveData = activeData?.toString();
    let obj = { [dataObj[api].saveData]: showData };
    obj[dataObj[api].title] = stringActiveData;

    setFilterData(obj);
  }, [activeData]);

  useEffect(() => {
    setShowData(filterData?.[dataObj[api].saveData] || []);
    const tempActiveData = filterData[dataObj[api].title]?.split(',').map((item) => item && parseInt(item));

    setActiveData(tempActiveData || []);
  }, [filterData]);

  const searchAdminProfiles = (role) => {
    let params = { search_str: inputValue, role };
    ApiClient.getSearchTeam(params)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchPatients = () => {
    ApiClient.getSearchPatients(inputValue)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchOrders = () => {
    ApiClient.searchOrders({ search_str: inputValue })
      .then((res) => {
        const modifiedData = res.data.map((order) => ({
          id: order?.order_id,
          title: order?.order_id,
          full_name: order?.order_id,
        }));

        setData(modifiedData);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };

  const searchVendors = () => {
    ApiClient.searchVendors({ q: inputValue })
      .then((res) => {
        const modifiedData = res.data.map((vendor) => ({
          ...vendor,
          full_name: vendor.vendor_name,
        }));

        setData(modifiedData);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };

  const dataObj = {
    team: { saveData: 'patient_data', title: 'patient_ids', func: searchPatients },
    article: { saveData: 'author_data', title: 'author_ids', func: searchAdminProfiles },
    patient: {
      saveData: 'admin_data',
      title: 'admin_ids',
      func: searchAdminProfiles,
      role:
        labels?.patientFilterSearch?.searchRoles ||
        'doctor,health_coach,health_coach_lead,diet_health_coach,fitness_health_coach',
    },
    invoice: {
      saveData: 'invoice_data',
      title: 'invoice_ids',
      func: searchOrders,
    },
    vendor: {
      saveData: 'vendor_data',
      title: 'vendor_ids',
      func: searchVendors,
    },
  };

  const renderChips = () => {
    return (
      <div>
        <div className="mt-3" />
        <ChipsTagsView
          setActiveTags={setActiveData}
          activeTags={activeData}
          data={showData}
          handleRoles
          setData={setShowData}
        />
      </div>
    );
  };

  const renderHeading = () => {
    return <div className="filterHeadings mt-3">{title}</div>;
  };

  const returnSearchDropDown = () => {
    return (
      <CoreSearchDropDown
        {...props}
        isRightIconref
        setTimeOutData={() => dataObj[api].func(dataObj[api].role)}
        inputValue={inputValue}
        inputStyle={{ height: '39px' }}
        data={data}
        setData={setData}
        setInputValue={setInputValue}
        setShowData={setShowData}
        setActiveData={setActiveData}
        showData={showData}
        activeData={activeData}
      />
    );
  };

  return (
    <div>
      {renderHeading()}
      {returnSearchDropDown()}
      {renderChips()}
    </div>
  );
}

export default AuthorFilter;
