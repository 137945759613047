import React, { useEffect, useRef, useState } from 'react';
import { closeIcon } from 'res/images';
import useApiManager from 'networking/ApiManager';
import CoreButton from './CoreButton';
import Select2DropDown from './common/Select2DropDown';
import CoreInputBox from './CoreInputBox';
import CoreRadioBox from './CoreRadioBox';
import { Stack } from 'rsuite';
import './payoutModal.css';
import { toast } from 'react-toastify';

function PayoutModal({ payoutData, isEdit, setRefreshData, payoutId, callOrders }) {
  const ApiClient = useApiManager();
  const modalRef = useRef(null);

  const [formData, setFormData] = useState({
    invoiceId: '',
    vendorName: '',
    payment_amount: '',
    payment_type: '',
    reference_id: '',
    payout_reference_id: 'Payout Reference ID will be generated after payment',
    status: 'PENDING',
  });

  const [formErrors, setFormErrors] = useState({
    vendorName: '',
    payment_amount: '',
    payment_type: '',
    reference_id: '',
  });

  const payment_types = [
    { title: 'Bank Transfer', id: 'BANK_TRANSFER' },
    { title: 'Credit Card', id: 'CREDIT_CARD' },
    { title: 'Cash', id: 'CASH' },
    { title: 'Others', id: 'OTHERS' },
  ];

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...payoutData,
    }));
  }, [payoutData]);

  const handleSelect = (key, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [key]: value,
      };
    });
    setFormErrors((prevErrors) => {
      return {
        ...prevErrors,
        [key]: '',
      };
    });
  };

  const validateForm = () => {
    let errors = {
      vendorName: '',
      payment_amount: '',
      payment_type: '',
      reference_id: '',
    };
    let isValid = true;

    if (!formData.payment_amount) {
      errors.payment_amount = 'Payment Amount is required.';
      isValid = false;
    }
    if (!formData.payment_amount) {
      errors.payment_type = 'Payment Type is required.';
      isValid = false;
    }

    if (!formData.reference_id) {
      errors.reference_id = 'Reference ID is required.';
      isValid = false;
    }

    if (!formData.vendorName) {
      errors.vendorName = 'Vendor Name is required.';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const getCreateFormBody = () => {
    return isEdit
      ? {
          payout_amount: formData?.payment_amount,
          payout_type: formData?.payment_type,
          reference_id: formData?.reference_id,
          status: formData?.status,
        }
      : {
          invoice: formData?.invoiceId,
          payout_amount: formData?.payment_amount,
          payout_type: formData?.payment_type,
          reference_id: formData?.reference_id,
          vendor_name: formData?.vendorName,
          status: formData?.status,
        };
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    let modalDiv = document.getElementById('payoutModalContainer');
    try {
      const formDataBody = getCreateFormBody();

      const apiCall = isEdit
        ? ApiClient.editPayout({ id: payoutId, data: formDataBody })
        : ApiClient.createPayout({ data: formDataBody });

      apiCall
        .then(() => {
          toast.success(`Payout ${isEdit ? 'Edited' : 'Generated'} successfully`);
          setRefreshData && setRefreshData((prevData) => !prevData);
          callOrders && callOrders();
        })
        .catch((error) => {
          toast.error('Error Generating Payout');
          console.error('Error submitting form:', error);
        });
    } finally {
      modalDiv?.click();
    }
  };

  const filterHeader = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
        <div
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          id="payoutModalContainer"
          style={{
            backgroundColor: '#ECECEC',
            borderRadius: 15,
            padding: 1,
            alignSelf: 'flex-end',
            marginBottom: 10,
          }}
          onPress={() => {
            setFormData({
              invoiceId: '',
              vendorName: '',
              payment_amount: '',
              payment_type: '',
              reference_id: '',
              payout_reference_id: 'Payout Reference ID will be generated after payment',
              status: 'PENDING',
            });
          }}
        >
          <img src={closeIcon} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ fontWeight: 600, fontSize: 14 }}>{isEdit ? 'Edit Payout' : 'Generate Payout'}</p>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div style={{ padding: 40 }}>
        <div style={{ marginBottom: 10, color: '#133696', fontWeight: 600, fontSize: 16 }}>Details</div>
        <div style={{ marginBottom: 10 }}>
          <CoreInputBox
            retuired
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            label="Vendor Name"
            placeholder="Vendor Name"
            value={formData?.vendorName}
            showMSG={formErrors.vendorName}
            setValue={(data) => {
              handleSelect('vendorName', data);
            }}
            disabled={isEdit}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <CoreInputBox
            validatedecimal
            retuired
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            label="Payment Amount"
            placeholder="Payment Amount"
            value={formData?.payment_amount}
            showMSG={formErrors.payment_amount}
            setValue={(data) => {
              handleSelect('payment_amount', data);
            }}
          />
        </div>
        <div id="datePickerContain" className="position-relative mb-2">
          <Stack direction="column" spacing={8} alignItems="auto">
            <Select2DropDown
              retuired
              value={formData?.payment_type}
              data={payment_types}
              container={() => document.getElementById('datePickerContain')}
              showMSG={formErrors.payment_type}
              labelKey="title"
              label={'Payout Type'}
              placeholder={'Payout Type'}
              valueKey="id"
              onSelect={(data) => {
                handleSelect('payment_type', data);
              }}
            />
          </Stack>
        </div>
        <div className=" mb-2">
          <CoreInputBox
            retuired
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            label="Reference ID"
            placeholder="Reference ID"
            value={formData?.reference_id}
            showMSG={formErrors.reference_id}
            setValue={(data) => {
              handleSelect('reference_id', data);
            }}
          />
        </div>
        <div className=" mb-2">
          <CoreInputBox
            disabled
            retuired
            inputStyle={{}}
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            label="Payout Reference ID"
            placeholder="Payout Reference ID"
            value={formData?.payout_reference_id}
          />
        </div>
        <div>
          <div style={{ color: 'var(--lightBlack)' }}>Status</div>
          <div className="mt-2" style={{ display: 'flex', flexDirection: 'row' }}>
            <CoreRadioBox
              value={'PENDING'}
              checked={formData?.status === 'PENDING'}
              onChange={(val) => {
                handleSelect('status', val);
              }}
              id={`before`}
              htmlFor={`before`}
              className={`me-5`}
              title="Pending"
              name={`status`}
            />
            <CoreRadioBox
              checked={formData?.status === 'COMPLETED'}
              onChange={(val) => {
                handleSelect('status', val);
              }}
              value={'COMPLETED'}
              id={`with`}
              htmlFor={`with`}
              className={`me-4 `}
              title="Completed"
              name={`status`}
            />
          </div>
        </div>
        <CoreButton onClick={handleSubmit} customClass="btn btn-secondary btn-lg" title="Save Changes" />
      </div>
    );
  };

  const renderModal = () => {
    return (
      <div className="filterModal">
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="payoutModal"
          aria-labelledby="payoutModalLabel"
          ref={modalRef}
        >
          <div className="whiteBackground" style={{ width: '550px' }}>
            {filterHeader()}
            <div className="filterHeaderData">{renderFilters()}</div>
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default PayoutModal;
