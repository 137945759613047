import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GraphListView } from 'scenes/patient/';
import CreatePatient from 'scenes/CreatePatient';
import AddGoal from 'whealth-core-web/components/AddGoal';
import AddPrescription from 'whealth-core-web/components/AddPrescription';
import UploadRport from 'whealth-core-web/components/UploadRport';
import {
  LoginScene,
  ArticleList,
  CreateArticles,
  Settings,
  Dashboard,
  Team,
  CreateTeam,
  Patient,
  ShowPatientProfile,
  ArticleView,
  Cockpit,
  Segment,
  Sales,
} from '../scenes';
import PrescriptionListView from 'scenes/patient/PrescriptionListView';
import AddQuestions from 'whealth-core-web/components/patient/AddQuestions';
import AddQuestionForm from 'whealth-core-web/forms/AddQuestionForm';
import { Appointment, SegmentDefinitionForm } from 'whealth-core-web/components';
import { ZohoCallback } from 'whealth-core-web/components/ZohoCalendar/ZohoCallback';
import AddSummary from 'whealth-core-web/components/AddSummary';
import AddConsultation from 'whealth-core-web/components/patient/AddConsultation';
import AddDietPlan from 'whealth-core-web/components/patient/AddDietPlan';
import WebEngage from 'whealth-core-web/components/webEngage/WebEngage';
import Orders from 'whealth-core-web/components/Orders/Orders';
import AddTeleConsultation from 'whealth-core-web/components/patient/AddTeleConsultation';
import PatientGoal from 'whealth-core-web/components/PatientGoal/PatientGoal';
import PatientGoalDetail from 'whealth-core-web/components/PatientGoal/PatientGoalDetail';
import TeleconsultationDetail from 'whealth-core-web/components/patient/TeleconsultationDetail';
import JoinRoom from 'whealth-core-web/components/JoinRoom';
import AddService from 'whealth-core-web/AddService';
import AppointmentVer2 from 'whealth-core-web/components/AppointmentVer2/AppointmentVer2';
import SideBarReport from 'whealth-core-web/SideBarReport';
import Baselining from 'whealth-core-web/components/patient/Baselining';
import AddOrder from 'whealth-core-web/components/Orders/AddOrder';
import RenderApolloTab from 'whealth-core-web/components/Apollo/RenderApolloTab';
import PatientOrder from 'whealth-core-web/components/Apollo/PatientOrder';
import ManageInventory from 'whealth-core-web/components/Orders/ManageInventory';
import CreatePlan from 'scenes/CreatePlan';
import Invoices from 'whealth-core-web/components/Invoices/Invoices';
import AddInvoicePayouts from 'whealth-core-web/components/Invoices/AddInvoicePayouts';
import Payouts from 'whealth-core-web/components/Payouts/Payouts';
import ViewInvoice from 'whealth-core-web/components/Invoices/ViewInvoice';
import ViewPayout from 'whealth-core-web/components/Payouts/ViewPayout';

const AllRoutes = () => {
  const acessTokenLocal = localStorage.getItem('token');
  const { acessToken } = useSelector((state) => state.sliceReducer);

  if (acessToken || acessTokenLocal) {
    return (
      <Routes>
        <Route path="/" exact element={<Dashboard />} />
        <Route path="/dashboard" exact element={<Dashboard />} />
        <Route path="/zoho-callback" exact element={<ZohoCallback />} />
        <Route path="/articles" exact element={<ArticleList />} />
        <Route path="/cockpit" exact element={<Cockpit />} />
        <Route path="/segments" exact element={<Segment />} />
        <Route path="/segments/new" exact element={<SegmentDefinitionForm />} />
        <Route path="/segments/:id/edit" exact element={<SegmentDefinitionForm />} />
        <Route path="/articles/new" exact element={<CreateArticles />} />
        <Route path="/articles/:id/details/:title" exact element={<ArticleView />} />
        <Route path="/articles/:id/details/" exact element={<ArticleView />} />
        <Route path="/articles/:id/edit" exact element={<CreateArticles />} />
        <Route path="/home" exact element={<ArticleList />} />
        <Route path="/patient" exact element={<Patient />} />
        <Route path="/patient/:id/dashboard" exact element={<ShowPatientProfile />} />
        <Route path="/patient/:id/goals/new" exact element={<AddGoal />} />
        <Route path="/patient/:id/goals/:goalid/edit" exact element={<AddGoal />} />
        <Route path="/patient/:id/reports/new" exact element={<UploadRport />} />
        <Route path="/patient/:id/reports/:reportid/edit" exact element={<UploadRport />} />
        <Route path="/patient/:id/prescriptions/new" exact element={<AddPrescription />} />
        <Route path="/patient/:id/summaries/new" exact element={<AddSummary />} />
        <Route path="/reports" exact element={<SideBarReport />} />
        <Route path="/patient/:id/summaries/:pid/edit" exact element={<AddSummary />} />
        <Route path="/patient/:id/questions/new" exact element={<AddQuestions />} />
        <Route path="/patient/:id/dietPlan/new" exact element={<AddDietPlan />} />
        <Route path="/patient/:id/teleconsultation/new" exact element={<AddTeleConsultation />} />
        <Route path="/patient/:id/teleconsultation/detail" exact element={<TeleconsultationDetail />} />
        <Route path="/patient/:id/teleconsultation/:teleId/joinRoom/:code" exact element={<JoinRoom />} />
        <Route path="/patient/:id/service/new" exact element={<AddService />} />
        <Route path="/patient/:id/service/:pid/edit" exact element={<AddService />} />
        <Route path="/patient/:id/prescriptions/:pid/edit" exact element={<AddPrescription />} />
        <Route path="/patient/:id/questions/:pid/edit" exact element={<AddQuestions />} />
        <Route path="/patient/:id/dietPlan/:pid/edit" exact element={<AddDietPlan />} />

        <Route path="/patient/:patientId/appointment/new" exact element={<AddConsultation />} />
        <Route path="/patient/:patientId/appointment/:consultationId/edit" exact element={<AddConsultation />} />
        <Route path="/sales" exact element={<Sales />} />

        <Route path="/team" exact element={<Team />} />
        <Route path="/team/new" exact element={<CreateTeam />} />
        <Route path="/patient/new" exact element={<CreatePatient />} />
        <Route path="/patient/:id/edit" exact element={<CreatePatient />} />
        <Route path="/patient/:id/baselining" exact element={<Baselining />} />

        <Route path="/team/:id/edit" exact element={<CreateTeam />} />
        <Route path="/settings" exact element={<Settings />} />
        <Route path="/patient/:id/:pageTitle/:gid/show" exact element={<PatientGoalDetail />} />
        <Route path="//patient/:id/prescription/:pid/medicines/:mid/show" exact element={<PrescriptionListView />} />
        <Route path="/notifications" exact element={<WebEngage />} />
        <Route path="/orders" exact element={<Orders />} />
        <Route path="/orders/:index/:orderId/:patientId" exact element={<Orders index={true} />} />
        <Route path="/patient/:id/dashboard/orders/:oid/:index" exact element={<PatientOrder index={true} />} />
        <Route path="/orders/new-orders" exact element={<AddOrder />} />
        <Route path="/orders/new-orders/:patientId" exact element={<AddOrder />} />
        <Route path="/orders/:index/:orderId/:patientId/:orderStatus" exact element={<AddOrder index={true} />} />
        <Route path="/appointment" exact element={<AppointmentVer2 />} />
        <Route path="/appointment2" exact element={<AppointmentVer2 />} />
        <Route path="/manage-inventory" exact element={<ManageInventory />} />

        <Route path="/team/:id/edit" exact element={<CreateTeam />} />
        <Route path="/settings" exact element={<Settings />} />
        <Route path="/patient/:id/:pageTitle/:gid/show" exact element={<PatientGoalDetail />} />
        <Route path="//patient/:id/prescription/:pid/medicines/:mid/show" exact element={<PrescriptionListView />} />
        <Route path="/notifications" exact element={<WebEngage />} />
        <Route path="/orders" exact element={<Orders />} />
        <Route path="/orders/:index/:orderId/:patientId/:idStr" exact element={<Orders index={true} />} />
        <Route path="/patient/:id/dashboard/orders/:oid/:index" exact element={<PatientOrder index={true} />} />
        <Route path="/orders/new-orders" exact element={<AddOrder />} />
        <Route path="/orders/new-orders/:patientId" exact element={<AddOrder />} />
        <Route
          path="/orders/:index/:orderId/:patientId/:orderStatus/:idStr"
          exact
          element={<AddOrder index={true} />}
        />
        <Route path="/appointment" exact element={<AppointmentVer2 />} />
        <Route path="/appointment2" exact element={<AppointmentVer2 />} />
        <Route path="/manage-inventory" exact element={<ManageInventory />} />
        <Route path="/plan/new" exact element={<CreatePlan />} />
        <Route path="/invoices" exact element={<Invoices />} />
        <Route path="/invoices/add-invoice" exact element={<AddInvoicePayouts />} />
        <Route path="/payouts/add-payout" exact element={<AddInvoicePayouts index={true} />} />
        <Route path="/payouts" excat element={<Payouts />} />
        <Route path="/orders/:orderId/:patientId/view-invoice/:vendorId/" exact element={<ViewInvoice />} />
        <Route path="/orders/:orderId/:patientId/view-payout/:payoutId/" exact element={<ViewPayout />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" exact element={<LoginScene />} />
        <Route path="/articles/:id/details/" exact element={<ArticleView />} />
        <Route path="/articles/:id/details/:title" exact element={<ArticleView />} />
      </Routes>
    );
  }
};

export default AllRoutes;
